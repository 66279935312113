import {
  Banner,
  BannerVariant,
  Box,
  ColorPreset,
  FontWeight,
  PlainButton,
  Separator,
  Space,
  Text,
  Visibility,
} from "@gocardless/flux-react";
import { ToTranslate } from "src/components/i18n";
import { useSwitchOrganisation } from "src/common/hooks/useSwitchOrganisation";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";

import { useOrgSwitcherData } from "../../Home/components/OrganisationSwitcher/hooks/useOrgSwitcherData";
import { useMultiEntityFeature } from "../hooks/useMultiEntityFeature";

export const OrganisationBanner: React.FC = () => {
  const {
    returnToParentOrganisation,
    isBannerHidden,
    setIsBannerHidden,
    prevAccountId,
  } = useSwitchOrganisation();
  const { loggedInOrganisation } = useOrgSwitcherData();
  const { hasMultiEntityFeature } = useMultiEntityFeature();
  const { i18n } = useLingui();

  const showOrganisationBanner =
    hasMultiEntityFeature && isBannerHidden !== "true";

  return showOrganisationBanner ? (
    <Banner
      closeAction={{
        onClose: () => {
          setIsBannerHidden("true");
        },
        label: i18n._(t({ id: "close", message: "Close" })),
      }}
      variant={BannerVariant.FullBleed}
    >
      <Box>
        <Text layout={["block", null, "inline-block"]}>
          <ToTranslate>
            You are now logged in as{" "}
            <Text weight={FontWeight.SemiBold}>
              {loggedInOrganisation?.name}
            </Text>
            .
          </ToTranslate>
        </Text>
        <Visibility visible={["none", null, "inline-block"]}>
          <Separator
            color={ColorPreset.BorderOnLight_03}
            direction="inline-vertical"
            spacing={0.5}
            thickness={1}
          />
        </Visibility>
        <Space layout={["block", null, "inline"]} v={[0.25, null, 0]} />
        {prevAccountId && (
          <PlainButton onClick={() => returnToParentOrganisation()}>
            <Text decoration="underline">
              <ToTranslate>Switch again</ToTranslate>
            </Text>
          </PlainButton>
        )}
      </Box>
    </Banner>
  ) : null;
};
